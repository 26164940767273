define("discourse/plugins/discourse-user-feedbacks/discourse/components/rating-input", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["user-ratings"],
    value: 0,
    checkedOne: false,
    checkedTwo: false,
    checkedThree: false,
    checkedFour: false,
    checkedFive: false,
    percentageOne: 68,
    didReceiveAttrs() {
      this._super(...arguments);
      this.changeRating();
    },
    changeRating(value) {
      if (value && this.readOnly) return;
      if (value > 0) {
        this.set("value", value);
      } else {
        this.set("value", this.value);
      }
    },
    checkedOne(value) {
      if (parseInt(value) >= 1) {
        return true;
      }
      return false;
    },
    checkedTwo(value) {
      if (parseInt(value) >= 2) {
        return true;
      }
      return false;
    },
    checkedThree(value) {
      if (parseInt(value) >= 3) {
        return true;
      }
      return false;
    },
    checkedFour(value) {
      if (parseInt(value) >= 4) {
        return true;
      }
      return false;
    },
    checkedFive(value) {
      if (parseInt(value) >= 5) {
        return true;
      }
      return false;
    },
    percentageOne(value) {
      if (!this.checkedOne) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageTwo(value) {
      if (this.checkedOne && !this.checkedTwo) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageThree(value) {
      if (this.checkedTwo && !this.checkedThree) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageFour(value) {
      if (this.checkedThree && !this.checkedFour) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageFive(value) {
      if (this.checkedFour && !this.checkedFive) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    }
  }, [["method", "changeRating", [_object.action]], ["method", "checkedOne", [(0, _decorators.default)("value")]], ["method", "checkedTwo", [(0, _decorators.default)("value")]], ["method", "checkedThree", [(0, _decorators.default)("value")]], ["method", "checkedFour", [(0, _decorators.default)("value")]], ["method", "checkedFive", [(0, _decorators.default)("value")]], ["method", "percentageOne", [(0, _decorators.default)("value")]], ["method", "percentageTwo", [(0, _decorators.default)("value")]], ["method", "percentageThree", [(0, _decorators.default)("value")]], ["method", "percentageFour", [(0, _decorators.default)("value")]], ["method", "percentageFive", [(0, _decorators.default)("value")]]]));
});