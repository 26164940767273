define("discourse/plugins/discourse-user-feedbacks/discourse/controllers/user-feedbacks", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _ajax, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    rating: 0,
    review: "",
    readOnly: false,
    placeholder: _I18n.default.t("discourse_user_feedbacks.user_feedbacks.user_review.placeholder"),
    canGiveFeedback(feedback_to_id) {
      return feedback_to_id !== this.currentUser && this.currentUser.id;
    },
    disabled(rating) {
      return !parseInt(rating) > 0;
    },
    createFeedback() {
      this.set("readOnly", true);
      (0, _ajax.ajax)("/user_feedbacks", {
        type: "POST",
        data: {
          rating: parseInt(this.rating),
          review: this.review,
          feedback_to_id: this.feedback_to_id
        }
      }).then(response => {
        this.model.feedbacks.unshiftObject(response.user_feedback);
        this.set("rating", 0);
        this.set("review", "");
      });
    }
  }, [["method", "canGiveFeedback", [(0, _decorators.default)("feedback_to_id")]], ["method", "disabled", [(0, _decorators.default)("rating")]], ["method", "createFeedback", [_object.action]]]));
});