define("discourse/plugins/discourse-user-feedbacks/discourse/user-feedback-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    map() {
      this.route("feedbacks");
    }
  };
});