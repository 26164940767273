define("discourse/plugins/discourse-user-feedbacks/discourse/templates/connectors/user-main-nav/user-feedback", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#link-to "user.feedbacks" model}}{{d-icon "star"}}{{i18n 'user.feedback.title'}}{{/link-to}}
  
  */
  {
    "id": "zqRrulgy",
    "block": "[[[6,[39,0],[\"user.feedbacks\",[30,0,[\"model\"]]],null,[[\"default\"],[[[[1,[28,[35,1],[\"star\"],null]],[1,[28,[35,2],[\"user.feedback.title\"],null]]],[]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-user-feedbacks/discourse/templates/connectors/user-main-nav/user-feedback.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-user-feedbacks/discourse/templates/connectors/user-main-nav/user-feedback.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});