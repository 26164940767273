define("discourse/plugins/discourse-user-feedbacks/discourse/routes/user-feedbacks", ["exports", "discourse/routes/discourse", "discourse/lib/ajax"], function (_exports, _discourse, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)("/user_feedbacks.json", {
        type: "GET",
        data: {
          feedback_to_id: this.modelFor("user").get("id")
        }
      }).then(response => {
        return response;
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        feedback_to_id: this.modelFor("user").get("id"),
        readOnly: this.currentUser && this.currentUser.feedbacks_to.includes(this.modelFor("user").get("id")),
        model: model
      });
    }
  });
});