define("discourse/plugins/discourse-user-feedbacks/discourse/connectors/user-main-nav/user-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      if (!component.currentUser) {
        return false;
      }
      if (args.model.id <= 0 || component.siteSettings.user_feedbacks_hide_feedbacks_from_user && component.currentUser.id == args.model.id && !component.currentUser.admin) {
        return false;
      }
      return true;
    }
  };
});