define("discourse/plugins/discourse-user-feedbacks/discourse/connectors/user-profile-primary/average-ratings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      if (args.model.id <= 0) {
        return false;
      }
      if (!component.siteSettings.user_feedbacks_display_average_ratings_on_profile) {
        return false;
      }
      return true;
    }
  };
});